<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="modelRef" @finish="onSearch">
			    <a-row>
			        <a-form-item name="title" class="ui-form-item" label="任务名称">
			            <a-input v-model:value="modelRef.title" placeholder="请输入"/>
			        </a-form-item>
			
			        <a-form-item name="userName" class="ui-form-item" label="操作人姓名">
			            <a-input v-model:value="modelRef.userName" placeholder="请输入"></a-input>
			        </a-form-item>
			    </a-row>
			
			    <a-row>
			        <a-col :span="10">
						<div class="ui-warn">状态不是实时更新，请点击【搜索】按钮获取最新任务状态</div>
			        </a-col>
			        <a-col :span="14" style="text-align: right;">
			            <a-button style="margin-right: 20px;" type="primary" html-type="submit">搜索</a-button>
			            <a-button @click="resetBtn">重置</a-button>
			        </a-col>
			    </a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :dataSource="dataList" :columns="dataColumns" rowKey="id" :pagination="pagination">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'type'">
						{{ transType(record.type) }}
					</template>
					<template v-if="column.key === 'status'">
						{{ transStatus(record.status) }}
					</template>
					<template v-if="column.key === 'fileStatus'">
						{{ transFileStatus(record.fileStatus) }}
					</template>
					<template v-if="column.key === 'finishTime'">
						{{ transDateTime(record.finishTime) }}
					</template>
					<template v-if="column.key === 'action'">
						<a v-permission="['system_task_list_download']" v-if="record.fileStatus == 1" :href="record.filePath">下载文件</a>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { getExportList } from '@/service/modules/export.js';
	export default {
		data() {
			return {
				loading: false,
				modelRef: {},
				searchData: {},
				dataColumns: [{
					title: '任务名称',
					dataIndex: 'title'
				}, {
					title: '导出类型',
					key: 'type'
				}, {
					title: '操作人姓名',
					dataIndex: 'userName'
				}, {
					title: '任务状态',
					key: 'status'
				}, {
					title: '文件状态',
					key: 'fileStatus'
				}, {
					title: '完成时间',
					key: 'finishTime'
				}, {
					title: '操作',
					key:'action'
				}],
				dataList:[],
				pagination: {
				    showSizeChanger: true,
				    showQuickJumper: true,
				    size: "middle",
				    showTotal: (total) => {
				      return "共 " + total + " 条";
				    },
				    total: 0,
				    current: 1,
				    pageSize: 20,
				    onChange: (page) => {
				      this.pagination.current = page;
				      this.getData();
				    },
				    onShowSizeChange: (page, pageSize) => {
				      this.pagination.current = 1;
				      this.pagination.pageSize = pageSize;
				      this.getData();
				    }
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			transType(val) {
				if (val === 'cinemaOrderExportStrategy') return '影票订单导出';
				if (val === 'cinemaOrderItemExportStrategy') return '影票订单子单导出';
				if (val === 'baseUserExportStrategy') return '用户导出';
				if (val === 'couponPacketOrderStrategy') return '券礼包订单导出';
				if (val === 'shopAfterSaleExportStrategy') return '商城售后订单导出';
				if (val === 'shopOrderExportStrategy') return '商城订单导出';
				if (val === 'snackOrderExportStrategy') return '卖品订单导出';
				if (val === 'storeGoodsStockLogExportStrategy') return '卖品出入库记录导出';
				if (val === 'unionCardArchiveCodeExportStrategy') return '一卡通生成记录详情导出';
				if (val === 'unionCardGeneratorRecordExportStrategy') return '一卡通生成记录导出';
				if (val === 'userUnionCardChargeOrderStrategy') return '一卡通充值记录导出';
				if (val === 'userUnionCardExportStrategy') return '一卡通绑定信息导出';
				if (val === 'userUnionCardOrderStrategy') return '一卡通订单导出';
				if (val === 'userUnionCardOutComeStrategy') return '一卡通消费记录导出';
 			},
			transStatus(val) {
				if (val === 0) return '已就绪';
				if (val === 1) return '进行中';
				if (val === 2) return '已完成';
				if (val === 3) return '异常';
			},
			transFileStatus(val) {
				if (val === 0) return '未生成';
				if (val === 1) return '可下载';
				if (val === 2) return '已清除';
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.modelRef));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getExportList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code ===200) {
						this.pagination.total = ret.data.totalCount;
						this.dataList = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form-item {
		margin-right: 20px;
	}
	.ui-warn {
		padding-top: 4px;
		font-size: 16px;
		color: red;
	}
</style>
